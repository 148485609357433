import React from 'react';
import styled from 'styled-components';
import { GradientBox } from './GradientBox';
import { getFontValue } from '../config/theme';
import { useStores } from '../stores/StoreContext';
import { observer } from 'mobx-react';
import { Zone } from '../models/Zone';

const Box = styled(GradientBox)`
  height: 130px;
`;

const BoxColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &:nth-child(2)::before {
    position: absolute;
    left: 0;
    content: '';
    height: 100px;
    width: 2px;
    background-color: #141d20;
  }

  &:nth-child(2)::after {
    position: absolute;
    right: 0;
    content: '';
    height: 100px;
    width: 2px;
    background-color: ${({ theme }) => theme.colors.boxSeparator};
  }
`;

const Value = styled.span`
  ${getFontValue('l', 800)};
  color: ${({ theme }) => theme.colors.text.subtle};
`;

const Label = styled.span`
  ${getFontValue('m')};
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.text.subtle};
  text-transform: uppercase;
`;

export const RaidTimerDisplay: React.FC = observer(() => {
  const { blizzardStore } = useStores();
  const { getZoneByName } = blizzardStore;

  return (
    <Box>
      <BestRaidTimeForZoneDisplay zone={getZoneByName('Blackwing Lair')} />
      <BestRaidTimeForZoneDisplay zone={getZoneByName('Molten Core')} />
      <BestRaidTimeForZoneDisplay zone={getZoneByName(`Temple of Ahn'Qiraj`)} />
    </Box>
  );
});

type BestRaidTimeForZoneDisplayProps = {
  zone?: Zone;
};

const BestRaidTimeForZoneDisplay: React.FC<BestRaidTimeForZoneDisplayProps> = observer(
  ({ zone }) => {
    const { raidStore } = useStores();
    const { getBestRaidTimeByZoneId } = raidStore;

    if (!zone) {
      return null;
    }

    let formattedDuration: string | number = 'N/A';
    const duration = getBestRaidTimeByZoneId(zone.id);

    if (duration) {
      formattedDuration = getFormattedDurationString(duration);
    }

    return (
      <BoxColumn>
        <Value>{formattedDuration}</Value>
        <Label>{zone.name}</Label>
      </BoxColumn>
    );
  }
);

const getFormattedDurationString = (duration: number): string => {
  const hours = duration / (1000 * 60 * 60);
  const flooredHours = Math.floor(hours);

  const minutes = (hours - flooredHours) * 60;
  const flooredMinutes = Math.floor(minutes);

  const seconds = (minutes - flooredMinutes) * 60;
  const ceiledSeconds = Math.ceil(seconds);

  const formattedHours = flooredHours.toString().padStart(2, '0');
  const formattedMinutes = flooredMinutes.toString().padStart(2, '0');
  const formattedSeconds = ceiledSeconds.toString().padStart(2, '0');

  if (flooredHours === 0) {
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};
