import React from 'react';
import { Tooltip as BaseTooltip } from '@reach/tooltip';

type TooltipProps = {
  label: string;
};

export const Tooltip: React.FC<TooltipProps> = ({ label, children }) => (
  <BaseTooltip
    label={label}
    style={{
      background: 'hsla(0, 0%, 0%, 0.75)',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      padding: '0.5em 1em',
    }}
  >
    {children}
  </BaseTooltip>
);
