import React from 'react';
import { ViewContainer } from '../components';
import { PageTitle } from '../components/Typography';
import { SearchBar } from '../components/SearchBar';
import { useStores } from '../stores/StoreContext';
import { LootBox } from '../components/LootBox';
import { observer } from 'mobx-react';
import { Loot } from '../models/Loot';
import { HeaderWithColumns } from '../components/HeaderWithColumns';
import DocumentTitle from 'react-document-title';

type ItemListViewProps = {};

const sortById = (a: Loot, b: Loot) => (a.id > b.id ? -1 : 1);

export const ItemListView: React.FC<ItemListViewProps> = observer(() => {
  const [searchValue, setSearchValue] = React.useState<string>('');
  const { blizzardStore } = useStores();
  const { loot } = blizzardStore;
  const [lootResults, setLootResults] = React.useState<Loot[]>([]);
  const initialLootResults = React.useMemo(() => {
    return loot.slice().sort(sortById).slice(0, 15);
  }, [loot]);

  const showLootResults = searchValue.length > 2;
  const lootResultsToShow = showLootResults ? lootResults : initialLootResults;

  React.useEffect(() => {
    if (searchValue.length <= 2) {
      return;
    }

    const newResults = loot.filter((item) =>
      item.name.toLowerCase().includes(searchValue)
    );

    setLootResults(newResults);
  }, [searchValue, loot]);

  return (
    <DocumentTitle title="Bifrost - Items">
      <ViewContainer>
        <HeaderWithColumns>
          <PageTitle>Items</PageTitle>
          <SearchBar
            value={searchValue}
            onChange={setSearchValue}
            placeholder="Search for an item"
          />
        </HeaderWithColumns>
        {lootResultsToShow.map((item) => (
          <LootBox key={item.id} loot={item} />
        ))}
      </ViewContainer>
    </DocumentTitle>
  );
});
