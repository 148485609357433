import styled, { css } from 'styled-components';

const activeStyle = css`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: ${({ theme }) => theme.colors.bg.activeButton};
`;

export const SquareButton = styled.button<{ size: number; active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size + 10}px;
  height: ${({ size }) => size + 10}px;
  border-radius: ${({ theme }) => theme.radius.m}px;
  border: none;
  background-color: transparent;
  transition: background-color 0.2s;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bg.activeButton};
  }

  ${({ active }) => active && activeStyle}
`;
