import * as React from 'react';
import { GuildStore } from './GuildStore';
import { MemberStore } from './MemberStore';
import { BlizzardStore } from './BlizzardStore';
import { RaidStore } from './RaidStore';

export interface StoreContextValue {
  guildStore: GuildStore;
  memberStore: MemberStore;
  blizzardStore: BlizzardStore;
  raidStore: RaidStore;
}

export const StoreContext = React.createContext<StoreContextValue>({} as any);

export function useStores(): StoreContextValue {
  const context = React.useContext(StoreContext);

  return context;
}
