import { decorate, observable, action, computed } from 'mobx';
import { Guild } from '../models/Guild';
import { api } from '../services/api';
import { Bifrost } from '../decls/bifrost';

export class GuildStore {
  guilds: Guild[] = [];
  activeGuildId: number | null = null;

  get getActiveGuild(): Guild | undefined {
    return this.guilds.find((guild) => guild.id === this.activeGuildId);
  }

  setGuilds(guilds: Guild[]): void {
    this.guilds = guilds;
  }

  setActiveGuild(guild: Guild | number): void {
    if (typeof guild === 'number') {
      this.activeGuildId = guild;
      return;
    }

    this.activeGuildId = guild.id;
  }

  async fetchGuilds() {
    try {
      const response = await api.get<Bifrost.API.GuildsResponseData>(
        '/guild/guilds'
      );
      if (response.status === 200) {
        this.setGuilds(response.data);
        // Set active guild id to 1 for now.
        this.setActiveGuild(1);
      }
    } catch (e) {
      // Report this or something.
    }
  }
}

decorate(GuildStore, {
  guilds: observable,
  activeGuildId: observable,
  getActiveGuild: computed,
  setGuilds: action,
  setActiveGuild: action,
});
