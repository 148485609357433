// @ts-nocheck
import React from 'react';
import { format } from 'date-fns';
import { DistributedLoot } from '../models/DistributedLoot';
import { Table } from './Table';
import { useStores } from '../stores/StoreContext';
import { Text } from './Typography';
import styled, { useTheme } from 'styled-components';
import { getFontValue } from '../config/theme';
import { Link } from './Link';
import { useTable, useSortBy } from 'react-table';
import { observer } from 'mobx-react';
import { LootResponse } from '../models/LootResponse';
import { SortIcon } from './SortIcon';
import { Character } from '../models/Character';

type LootHistoryTableProps = {
  items: DistributedLoot[];
};

const NoItems = styled.div`
  ${getFontValue('s')};
  color: ${({ theme }) => theme.colors.text.tertiary};
`;

const RenderCharacter: React.FC<{ value: Character }> = ({ value }) => {
  const { wowColors } = useTheme();

  return (
    <Link to={`/characters/${value.id}`} color={wowColors[value.classy]}>
      {value.name}
    </Link>
  );
};

const Response: React.FC<{ value: LootResponse }> = ({ value }) => {
  const { wowColors } = useTheme();

  return <Text color={wowColors[value]}>{value}</Text>;
};

export const LootHistoryTable: React.FC<LootHistoryTableProps> = observer(
  ({ items }) => {
    const { blizzardStore, raidStore } = useStores();
    const { getZoneById } = blizzardStore;
    const { getRaidById } = raidStore;

    const sortByCharacterName = React.useMemo(() => {
      return function (a: any, b: any, columnId: string, desc: boolean) {
        return a.original.character.name === b.original.character.name
          ? 0
          : a.original.character.name > b.original.character.name
          ? 1
          : -1;
      };
    }, []);

    const columns = React.useMemo(
      () => [
        { Header: 'Date', accessor: 'date' },
        { Header: 'Zone', accessor: 'zone' },
        {
          Header: 'Character',
          accessor: 'character',
          Cell: RenderCharacter,
          sortType: sortByCharacterName,
        },
        { Header: 'Response', accessor: 'response', Cell: Response },
        { Header: 'Time', accessor: 'time' },
      ],
      [sortByCharacterName]
    );

    const data = React.useMemo(() => {
      return items.map((loot) => {
        const raid = getRaidById(loot.raid);
        const zone = raid ? getZoneById(raid.zone) : null;
        const date = new Date(loot.dropped);

        return {
          date: format(date, 'yyyy-MM-dd'),
          zone: zone?.name,
          character: loot.received_by,
          response: loot.response,
          time: format(date, 'HH:ii'),
        };
      });
    }, [items, getRaidById, getZoneById]);

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

    if (items.length === 0) {
      return <NoItems>Nothing here yet</NoItems>;
    }

    return (
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {column.isSorted ? (
                    <SortIcon
                      direction={column.isSortedDesc ? 'desc' : 'asc'}
                    />
                  ) : (
                    ''
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
);
