import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import Search from '../assets/search.png';
import { getFontValue } from '../config/theme';

type SearchBarProps = {
  placeholder?: string;
  value: string;
  onChange(value: string): void;
};

const Wrapper = styled.div`
  position: relative;
`;

const SearchInput = styled.input`
  ${getFontValue('s')};
  color: ${({ theme }) => theme.colors.text.placeholder};
  border-radius: ${({ theme }) => theme.radius.l}px;
  background-color: ${({ theme }) => theme.colors.bg.search};
  border: 1px solid ${({ theme }) => theme.colors.bg.searchBorder};
  padding: 10px 10px 10px 38px;
  width: 273px;
  outline: 0;
  position: relative;
  transition: border 0.5s;

  &:focus {
    border-color: ${({ theme }) => theme.colors.bg.searchBorderActive};
  }
`;

const SearchIcon = styled.span`
  color: #fff;
  position: absolute;
  left: 10px;
  top: 11px;
  display: block;
  background-image: url(${Search});
  height: 17px;
  width: 17px;
  z-index: 10;
`;

export const SearchBar: React.FC<SearchBarProps> = ({
  placeholder,
  value,
  onChange,
}) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <Wrapper>
      <SearchIcon />
      <SearchInput
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
      />
    </Wrapper>
  );
};
