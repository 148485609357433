import styled, { css } from 'styled-components';
import { getFontValue } from '../config/theme';

type TypographyProps = {
  color?: string;
  uppercase?: boolean;
  className?: string;
};

type MarginProps = {
  marginTop?: number;
  marginRight?: number;
  marginBottom?: number;
  marginLeft?: number;
};

export const Text = styled.span<TypographyProps>(
  ({ color, theme }) => `
  color: ${color || theme.colors.text.secondary};
`
);

export const PageTitle = styled.h1`
  position: relative;
  ${getFontValue('xl', 800)};
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`;

export const Heading = styled.h2<MarginProps>`
  ${getFontValue('l', 800)};
  color: ${({ theme }) => theme.colors.text.primary};

  ${({ theme, marginTop, marginRight, marginBottom, marginLeft }) => css`
    margin-top: ${marginTop}px;
    margin-right: ${marginRight}px;
    margin-bottom: ${marginBottom || theme.spacing.l}px;
    margin-left: ${marginLeft}px;
  `};
`;

export const SubHeading = styled.span<{
  uppercase?: boolean;
  marginLeft?: number;
}>`
  ${getFontValue('s', 800)};
  color: ${({ theme }) => theme.colors.text.tertiary};
  vertical-align: middle;
  margin-left: ${({ marginLeft }) => marginLeft || 10}px;
  text-transform: ${({ uppercase = true }) =>
    uppercase ? 'uppercase' : 'none'};
`;

export const RightJustifiedHeading = styled(SubHeading)`
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 12px;
`;
