import React from 'react';
import { Zone } from '../models/Zone';
import { Table } from './Table';
import { useStores } from '../stores/StoreContext';
import { observer } from 'mobx-react';
import { RollStats } from '../models/RollStats';

type RollStatisticsTableProps = {
  stats: RollStats;
  zones: Zone[];
};

export const RollStatisticsTable: React.FC<RollStatisticsTableProps> = observer(
  ({ stats }) => {
    const { blizzardStore } = useStores();
    const { getZoneById } = blizzardStore;

    return (
      <Table>
        <thead>
          <tr>
            <th>Zone</th>
            <th>Priority</th>
            <th>Upgrade</th>
            <th>PvP</th>
            <th>Offspec</th>
            <th>Round Robin</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(stats).map(([zoneId, stat]) => (
            <tr key={zoneId}>
              <td>{getZoneById(Number(zoneId))?.name}</td>
              <td>{stat.Priority}</td>
              <td>{stat.Upgrade}</td>
              <td>{stat.PvP}</td>
              <td>{stat.Offspec}</td>
              <td>{stat['Round Robin']}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
);
