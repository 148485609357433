import React from 'react';
import { ViewContainer, Heading } from '../components';
import { CharacterList } from '../components/CharacterList';
import { RaidTimerDisplay } from '../components/RaidTimerDisplay';
import { useStores } from '../stores/StoreContext';
import { observer } from 'mobx-react';
import { PageTitle, SubHeading } from '../components/Typography';
import { HeaderWithColumns } from '../components/HeaderWithColumns';
import { FilterMembersButton } from '../components/FilterMembersButton';
import DocumentTitle from 'react-document-title';

export const GuildOverview: React.FC = observer(() => {
  const [includeInactiveMembers, setIncludeInactiveMembers] = React.useState<
    boolean
  >(false);
  const { memberStore } = useStores();

  const activeMemberCharacters = memberStore.members
    .filter((member) => member.is_active)
    .map((member) => member.character);

  const inActiveMemberCharacters = memberStore.members
    .filter((member) => !member.is_active)
    .map((member) => member.character);

  const activeIds = activeMemberCharacters.map((char) => char.id);

  const charactersToShow = [
    ...activeMemberCharacters,
    ...(includeInactiveMembers ? inActiveMemberCharacters : []),
  ];

  return (
    <DocumentTitle title="Bifrost">
      <ViewContainer>
        <PageTitle>Bifrost</PageTitle>
        <HeaderWithColumns>
          <Heading>
            Roster <SubHeading>{activeMemberCharacters.length}</SubHeading>
          </Heading>
          <FilterMembersButton
            size={30}
            on={includeInactiveMembers}
            onClick={() => setIncludeInactiveMembers((v) => !v)}
          />
        </HeaderWithColumns>
        <CharacterList
          characters={charactersToShow}
          activeIds={activeIds}
          withInactiveIndication
        />
        <RaidTimerDisplay />
      </ViewContainer>
    </DocumentTitle>
  );
});
