import React from 'react';
import { DistributedLootWithDetails } from '../models/DistributedLoot';
import { Heading } from './Typography';
import { DistributedLootBox } from './LootBox';
import { animated, useTrail } from 'react-spring';
import { observer } from 'mobx-react';

type RaidLootListProps = {
  loot: DistributedLootWithDetails[];
};

type BossLootListProps = {
  loot: DistributedLootWithDetails[];
};

const sortByDate = (
  a: DistributedLootWithDetails,
  b: DistributedLootWithDetails
) => (a.dropped > b.dropped ? -1 : 1);

const groupAndSortLootByBosses = (
  loot: DistributedLootWithDetails[]
): DistributedLootWithDetails[][] => {
  const grouped: DistributedLootWithDetails[][] = [[]];

  loot.forEach((item) => {
    if (!grouped[item.boss.order]) {
      grouped[item.boss.order] = [];
    }

    if (!item.boss.boss) {
      // Trash.
      grouped[0].push(item);
    } else {
      grouped[item.boss.order].push(item);
    }
  });

  grouped.forEach((group) => {
    group.sort(sortByDate);
  });

  return grouped.filter((group) => group.length > 0);
};

const BossLootList: React.FC<BossLootListProps> = observer(({ loot = [] }) => {
  if (!loot || loot.length === 0) {
    return null;
  }

  let bossName = 'Trash';
  const [firstDrop] = loot;

  if (firstDrop && firstDrop.boss.boss) {
    bossName = firstDrop.boss.name;
  }

  return (
    <>
      <Heading marginBottom={22} marginTop={47}>
        {bossName}
      </Heading>
      {loot.map((item) => (
        <DistributedLootBox key={item.id} loot={item} />
      ))}
    </>
  );
});

export const RaidLootList: React.FC<RaidLootListProps> = observer(
  ({ loot = [] }) => {
    const bossMap = loot.map((l) => l.boss.wowid);
    const amountOfBosses = [...Array.from(new Set(bossMap))].length;
    const trail = useTrail(amountOfBosses, {
      y: 0,
      opacity: 1,
      from: { y: 80, opacity: 0 },
    });

    const groupedAndSortedLoot = groupAndSortLootByBosses(loot);

    return (
      <>
        {trail.map(({ y, opacity }, index) => (
          <animated.div
            key={index}
            style={{
              opacity,
              transform: y.interpolate((y) => `translateY(${y}px)`),
            }}
          >
            <BossLootList loot={groupedAndSortedLoot[index]} />
          </animated.div>
        ))}
      </>
    );
  }
);
