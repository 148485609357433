import styled from 'styled-components';

export const GradientBox = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  box-shadow: ${theme.shadow.box};
  background: ${theme.gradient.box};
  border-radius: ${theme.radius.m}px;
`
);
