import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Character } from '../models/Character';
import { observer } from 'mobx-react';
import { groupArrayOfObjectsByProperty } from '../helpers';
import { Link } from './Link';
import { getFontValue } from '../config/theme';

type CharacterList = {
  characters: Character[];
  withInactiveIndication?: boolean;
  activeIds?: number[];
};

const Wrapper = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.xxl}px;
`;

const ClassColumn = styled.div`
  flex: 1;
`;

const CharacterContainer = styled.div`
  ${getFontValue('s', 800)};
`;

const sortByClass = (a: Character[], b: Character[]) => {
  const [charA] = a;
  const [charB] = b;

  return charA.classy > charB.classy ? 1 : -1;
};

export const CharacterList: React.FC<CharacterList> = observer(
  ({ characters, activeIds = [], withInactiveIndication = false }) => {
    const { wowColors, colors } = useTheme();
    const charactersGroupedByClass = groupArrayOfObjectsByProperty(
      characters,
      'classy'
    ).sort(sortByClass);

    return (
      <Wrapper>
        {charactersGroupedByClass.map((group: Character[], index) => (
          <ClassColumn key={index}>
            {group.map((character: Character) => (
              <CharacterContainer key={character.id}>
                <Link
                  to={`/characters/${character.id}`}
                  color={
                    withInactiveIndication
                      ? activeIds.includes(character.id)
                        ? wowColors[character.classy]
                        : colors.text.tertiary
                      : wowColors[character.classy]
                  }
                >
                  {character.name}
                </Link>
              </CharacterContainer>
            ))}
          </ClassColumn>
        ))}
      </Wrapper>
    );
  }
);
