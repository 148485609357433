import Druid from './druid.png';
import Hunter from './hunter.png';
import Mage from './mage.png';
import Paladin from './paladin.png';
import Priest from './priest.png';
import Rogue from './rogue.png';
import Warlock from './warlock.png';
import Warrior from './warrior.png';
const Shaman = '';

export {
  Druid,
  Hunter,
  Mage,
  Paladin,
  Priest,
  Rogue,
  Warlock,
  Warrior,
  Shaman,
};
