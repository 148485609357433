import React from 'react';
import { Class } from '../models/Class';
import styled from 'styled-components';
import * as ClassIcons from '../assets';

type ClassIconProps = {
  classy: Class;
};

const Image = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 10px;
`;

export const ClassIcon: React.FC<ClassIconProps> = ({ classy }) => (
  <Image src={ClassIcons[classy]} />
);
