import React from 'react';
import { DistributedLootWithDetails } from '../models/DistributedLoot';
import { GradientBox } from './GradientBox';
import styled, { useTheme } from 'styled-components';
import { getFontValue } from '../config/theme';
import { Link, WowheadLink } from './Link';
import { Loot } from '../models/Loot';

type LootBoxProps = {
  loot: Loot;
};

type DistributedLootBoxProps = {
  loot: DistributedLootWithDetails;
};

const Box = styled(GradientBox)`
  height: 60px;
  width: 100%;
  margin-bottom: 15px;
  border: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid #1c2325;
  }
`;

const LootTitle = styled.h2`
  flex: 1;
  ${getFontValue('ml', 600)};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-left: 20px;
  align-self: center;
`;

const LootReceiver = styled.h3`
  flex: 1;
  ${getFontValue('s', 600)};
  margin-right: 10px;
  align-self: center;
  text-align: right;
  line-height: 1;
`;

const LootRoll = styled.h4`
  width: 105px;
  ${getFontValue('xs', 600)};
  color: ${({ theme }) => theme.colors.text.tertiary};
  text-transform: uppercase;
  align-self: center;
  line-height: 1;
`;

export const DistributedLootBox: React.FC<DistributedLootBoxProps> = ({
  loot,
}) => {
  const { wowColors } = useTheme();
  const color = wowColors[loot.received_by.classy];

  return (
    <Link to={`/items/${loot.item.id}`}>
      <Box>
        <LootTitle>
          <WowheadLink to={loot.item.itemlink}>{loot.item.name}</WowheadLink>
        </LootTitle>
        <LootReceiver>
          <Link to={`/characters/${loot.received_by.id}`} color={color}>
            {loot.received_by.name}
          </Link>
        </LootReceiver>
        <LootRoll>{loot.response}</LootRoll>
      </Box>
    </Link>
  );
};

export const LootBox: React.FC<LootBoxProps> = ({ loot }) => (
  <Link to={`/items/${loot.id}`}>
    <Box>
      <LootTitle>
        <WowheadLink to={loot.itemlink}>{loot.name}</WowheadLink>
      </LootTitle>
    </Box>
  </Link>
);
