import React from 'react';
import { observer } from 'mobx-react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Navigation } from './components';
import { GuildOverview, CharacterView } from './views';
import { useStores } from './stores/StoreContext';
import { RaidListView } from './views/RaidListView';
import { Footer } from './components/ViewContainer';
import { RaidView } from './views/RaidView';
import { ItemListView } from './views/ItemListView';
import { ItemView } from './views/ItemView';

export const App: React.FC = observer(() => {
  const { guildStore, memberStore, blizzardStore, raidStore } = useStores();
  const { activeGuildId } = guildStore;

  React.useEffect(() => {
    guildStore.fetchGuilds();
    blizzardStore.fetchZones();
    blizzardStore.fetchLoot();
    raidStore.fetchRaids();
  }, [guildStore, blizzardStore, raidStore]);

  React.useEffect(() => {
    if (activeGuildId) {
      memberStore.fetchMembersForGuild(activeGuildId);
    }
  }, [activeGuildId, memberStore]);

  return (
    <>
      <Navigation />
      <Switch>
         <Redirect exact from="/guilds/details/1/" to="/" />
        <Route
          exact
          path="/characters/:characterId"
          component={CharacterView}
        />
        <Route path="/raids/:raidId" component={RaidView} />
        <Route path="/items/:itemId" component={ItemView} />
        <Route exact path="/raids" component={RaidListView} />
        <Route exact path="/items" component={ItemListView} />
        <Route exact path="/" component={GuildOverview} />
      </Switch>
      <Footer />
    </>
  );
});
