import React from 'react';
import AscendingIcon from '../assets/sortasc.png';
import DescendingIcon from '../assets/sortdesc.png';
import styled from 'styled-components';

type SortIconProps = {
  direction: 'asc' | 'desc';
};

const Icon = styled.img`
  pointer-events: none;
  position: absolute;
  top: 11px;
  width: 25px;
  height: 25px;
`;

export const SortIcon: React.FC<SortIconProps> = ({ direction }) => (
  <Icon src={direction === 'asc' ? AscendingIcon : DescendingIcon} />
);
