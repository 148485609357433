import { Raid } from '../models/Raid';
import { Guild } from '../models/Guild';
import { api } from '../services/api';
import { Bifrost } from '../decls/bifrost';
import { observable, decorate, action } from 'mobx';

export class RaidStore {
  raids: Raid[] = [];

  getRaidById = (id: number): Raid | undefined =>
    this.raids.find((raid) => raid.id === id);

  getRaidsByGuild = (guild: Guild | number): Raid[] => {
    const guildId = typeof guild === 'number' ? guild : guild.id;
    return this.raids.filter((raid) => raid.guild === guildId);
  };

  getBestRaidTimeByZoneId = (zoneId: number): number | undefined => {
    return this.raids
      .filter((raid) => raid.zone === zoneId)
      .map((raid) => raid.duration)
      .filter((duration) => duration > 0)
      .sort((a, b) => (a > b ? 1 : -1))
      .shift();
  };

  setRaids(raids: Raid[]): void {
    this.raids = raids;
  }

  async fetchRaids() {
    const response = await api.get<Bifrost.API.RaidsResponseData>(
      '/raid/raids'
    );

    if (response.status === 200) {
      this.setRaids(response.data);
    }
  }
}

decorate(RaidStore, {
  raids: observable,
  setRaids: action,
});
