import React from 'react';
import Eye from '../assets/eye.png';
import EyeOff from '../assets/eye-off.png';
import styled from 'styled-components';
import { SquareButton } from './SquareButton';

type FilterMembersButtonProps = {
  on: boolean;
  onClick(): void;
  size: number;
  style?: React.CSSProperties;
};

const Icon = styled.img<{ size: number }>(
  ({ size }) => `
  width: ${size}px;
  height: ${size}px;
`
);

export const FilterMembersButton: React.FC<FilterMembersButtonProps> = ({
  on,
  onClick,
  size,
  style,
}) => (
  <SquareButton
    onClick={onClick}
    size={size}
    style={style}
    title="Toggle inactive members"
  >
    <Icon src={on ? Eye : EyeOff} size={size} />
  </SquareButton>
);
