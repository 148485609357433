import React from 'react';
import { ViewContainer, Link } from '../components';
import { useStores } from '../stores/StoreContext';
import { Raid } from '../models/Raid';
import { observer } from 'mobx-react';
import { formatDistanceToNow, format } from 'date-fns';
import { PageTitle } from '../components/Typography';
import { GradientBox } from '../components/GradientBox';
import styled from 'styled-components';
import { getFontValue } from '../config/theme';
import { Tooltip } from '../components/Tooltip';
import { HeaderWithColumns } from '../components/HeaderWithColumns';
import { SortButton } from '../components/SortButton';
import { FilterButton } from '../components/FilterButton';
import DocumentTitle from 'react-document-title';

type RaidListViewProps = {};

const RaidBox = styled(GradientBox)`
  height: 60px;
  width: 100%;
  margin-bottom: 15px;
  border: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid #1c2325;
  }
`;

const RaidTitle = styled.h2`
  flex: 1;
  ${getFontValue('ml', 600)};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-left: 32px;
  align-self: center;
`;

const RaidDate = styled.h3`
  flex: 1;
  ${getFontValue('s', 600)};
  color: ${({ theme }) => theme.colors.text.tertiary};
  margin-right: 32px;
  align-self: center;
  text-align: right;
`;

const HeaderColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SingleRaid: React.FC<{ raid: Raid }> = observer(({ raid }) => {
  const { blizzardStore } = useStores();
  const { getZoneById } = blizzardStore;
  const zone = getZoneById(raid.zone);
  const raidDate = new Date(raid.raid_date);

  if (!zone) {
    return null;
  }

  return (
    <Link to={`/raids/${raid.id}`}>
      <RaidBox>
        <RaidTitle>{zone.name}</RaidTitle>
        <RaidDate>
          <Tooltip label={format(raidDate, 'yyyy-MM-dd')}>
            <span>{formatDistanceToNow(raidDate)} ago</span>
          </Tooltip>
        </RaidDate>
      </RaidBox>
    </Link>
  );
});

const sortByRaidDateDescending = (a: Raid, b: Raid) =>
  a.raid_date > b.raid_date ? -1 : 1;

const sortByRaidDateAscending = (a: Raid, b: Raid) =>
  a.raid_date > b.raid_date ? 1 : -1;

export const RaidListView: React.FC<RaidListViewProps> = observer(() => {
  const [descending, setDescending] = React.useState<boolean>(true);
  const { raidStore, guildStore, blizzardStore } = useStores();
  const { getRaidsByGuild } = raidStore;
  const guild = guildStore.getActiveGuild;
  const raids = guild ? getRaidsByGuild(guild) : [];
  const filterOptions = React.useMemo(
    () =>
      blizzardStore.zones
        .filter((zone) => zone.is_instance)
        .map((zone) => ({ label: zone.name, value: zone.id })),
    [blizzardStore.zones]
  );
  const [filters, setFilters] = React.useState<number[]>([]);

  React.useEffect(() => {
    setFilters(filterOptions.map((f) => f.value));
  }, [filterOptions]);

  const handleFilterChange = (filter: number) => {
    const isAlreadyApplied = filters.includes(filter);

    if (isAlreadyApplied) {
      setFilters(filters.filter((f) => f !== filter));
    } else {
      setFilters([...filters, filter]);
    }
  };

  const changeSortDirection = (): void => {
    setDescending((desc) => !desc);
  };

  const sortMethod = descending
    ? sortByRaidDateDescending
    : sortByRaidDateAscending;

  return (
    <DocumentTitle title="Bifrost - Raids">
      <ViewContainer>
        <HeaderWithColumns>
          <PageTitle>Raids</PageTitle>
          <HeaderColumn>
            <FilterButton
              size={30}
              options={filterOptions}
              currentFilters={filters}
              onChange={handleFilterChange}
            />
            <SortButton
              size={30}
              descending={descending}
              onClick={changeSortDirection}
            />
          </HeaderColumn>
        </HeaderWithColumns>
        {raids
          .filter((raid) => filters.includes(raid.zone))
          .sort(sortMethod)
          .map((raid) => (
            <SingleRaid key={raid.id} raid={raid} />
          ))}
      </ViewContainer>
    </DocumentTitle>
  );
});
