// @ts-nocheck
import React from 'react';
import { format } from 'date-fns';
import { DistributedLoot } from '../models/DistributedLoot';
import { Table } from './Table';
import { useStores } from '../stores/StoreContext';
import { Text } from './Typography';
import styled, { useTheme } from 'styled-components';
import { getFontValue } from '../config/theme';
import { WowheadLink } from './Link';
import { useTable, useSortBy } from 'react-table';
import { observer } from 'mobx-react';
import { LootResponse } from '../models/LootResponse';
import { Loot } from '../models/Loot';
import { SortIcon } from './SortIcon';

type LootTableProps = {
  items: DistributedLoot[];
};

const NoItems = styled.div`
  ${getFontValue('s')};
  color: ${({ theme }) => theme.colors.text.tertiary};
`;

const Item: React.FC<{ value: Loot }> = observer(({ value }) => (
  <WowheadLink itemID={value.wowid} to={`/items/${value.id}`}>{value?.name || ''}</WowheadLink>
));

const Response: React.FC<{ value: LootResponse }> = ({ value }) => {
  const { wowColors } = useTheme();

  return <Text color={wowColors[value]}>{value}</Text>;
};

export const LootTable: React.FC<LootTableProps> = observer(({ items }) => {
  const { blizzardStore, raidStore } = useStores();
  const { getLootById, getZoneById } = blizzardStore;
  const { getRaidById } = raidStore;

  const sortByItem = React.useMemo(() => {
    return function (a: any, b: any, columnId: string, desc: boolean) {
      return a.original.item.name === b.original.item.name
        ? 0
        : a.original.item.name > b.original.item.name
        ? 1
        : -1;
    };
  }, []);

  const columns = React.useMemo(
    () => [
      { Header: 'Date', accessor: 'date' },
      { Header: 'Zone', accessor: 'zone' },
      { Header: 'Item', accessor: 'item', Cell: Item, sortType: sortByItem },
      { Header: 'Response', accessor: 'response', Cell: Response },
      { Header: 'Time', accessor: 'time' },
    ],
    [sortByItem]
  );

  const data = React.useMemo(() => {
    return items.map((loot) => {
      const raid = getRaidById(loot.raid);
      const zone = raid ? getZoneById(raid.zone) : null;
      const date = new Date(loot.dropped);

      return {
        date: format(date, 'yyyy-MM-dd'),
        zone: zone?.name,
        item: getLootById(loot.item),
        response: loot.response,
        time: format(date, 'HH:ii'),
      };
    });
  }, [items, getLootById, getRaidById, getZoneById]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  if (items.length === 0) {
    return <NoItems>Nothing here yet</NoItems>;
  }

  return (
    <Table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                {column.isSorted ? (
                  <SortIcon direction={column.isSortedDesc ? 'desc' : 'asc'} />
                ) : (
                  ''
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
});
