import React from 'react';
import AscendingIcon from '../assets/sortasc.png';
import DescendingIcon from '../assets/sortdesc.png';
import styled from 'styled-components';
import { SquareButton } from './SquareButton';

type SortButtonProps = {
  descending: boolean;
  onClick(): void;
  size: number;
  style?: React.CSSProperties;
};

const Icon = styled.img<{ size: number }>(
  ({ size }) => `
  width: ${size}px;
  height: ${size}px;
`
);

export const SortButton: React.FC<SortButtonProps> = ({
  descending,
  onClick,
  size,
  style,
}) => (
  <SquareButton
    onClick={onClick}
    size={size}
    style={style}
    title="Change order"
  >
    <Icon src={descending ? DescendingIcon : AscendingIcon} size={size} />
  </SquareButton>
);
