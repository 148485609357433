import { Zone } from '../models/Zone';
import { api } from '../services/api';
import { decorate, observable, action } from 'mobx';
import { Bifrost } from '../decls/bifrost';
import { Loot } from '../models/Loot';

export class BlizzardStore {
  zones: Zone[] = [];
  loot: Loot[] = [];

  getZoneById = (id: number): Zone | undefined =>
    this.zones.find((zone) => zone.id === id);

  getZoneByName = (name: string): Zone | undefined =>
    this.zones.find((zone) => zone.name === name);

  getLootById = (id: number): Loot | undefined =>
    this.loot.find((loot) => loot.id === id);

  setZones(zones: Zone[]) {
    this.zones = zones;
  }

  setLoot(loot: Loot[]) {
    this.loot = loot;
  }

  async fetchZones() {
    const response = await api.get<Bifrost.API.ZonesResponseData>(
      '/blizzard/zones'
    );

    if (response.status === 200) {
      this.setZones(response.data.filter((zone) => zone.is_instance));
    }
  }

  async fetchLoot() {
    const response = await api.get<Bifrost.API.ItemsResponseData>(
      '/blizzard/items'
    );

    if (response.status === 200) {
      this.setLoot(response.data);
    }
  }
}

decorate(BlizzardStore, {
  zones: observable,
  loot: observable,
  setZones: action,
  setLoot: action,
});
