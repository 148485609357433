import React from 'react';
import { Link as RouterLink, NavLink as RouterNavLink } from 'react-router-dom';
import styled from 'styled-components';

type StyledLinkProps = {
  color?: string;
  className?: string;
};

type LinkProps = StyledLinkProps & {
  to?: string;
  itemID?: string;
};

type NavLinkProps = StyledLinkProps & {
  to: string;
  activeClassName?: string;
  exact?: boolean;
};

const StyledLink = styled(RouterLink)<StyledLinkProps>`
  color: ${({ theme, color }) => color || theme.colors.text.secondary};
  text-decoration: none;
`;

const StyledNavLink = styled(RouterNavLink)<StyledLinkProps>`
  color: ${({ theme, color }) => color || theme.colors.text.secondary};
  text-decoration: none;
`;

const WowheadAnchor = styled.a`
  text-decoration: none;

  span {
    padding-left: 5px;
    vertical-align: middle;
  }
`;

export const Link: React.FC<LinkProps> = ({
  to = '',
  children,
  color,
  className,
}) => (
  <StyledLink to={to} color={color} className={className}>
    {children}
  </StyledLink>
);

export const WowheadLink: React.FC<LinkProps> = ({ to, children, itemID }) => {
  React.useEffect(() => {
    window.$WowheadPower.refreshLinks();
  }, []);

  const extraProps = itemID
    ? {
        'data-wowhead': `item=${itemID}&domain=classic`,
        target: undefined,
        rel: undefined,
      }
    : {};

  return (
    <WowheadAnchor
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      {...extraProps}
    >
      {children}
    </WowheadAnchor>
  );
};

export const NavLink: React.FC<NavLinkProps> = ({
  to = '',
  children,
  color,
  className,
  activeClassName,
  exact,
}) => (
  <StyledNavLink
    to={to}
    color={color}
    className={className}
    activeClassName={activeClassName}
    exact={exact}
  >
    {children}
  </StyledNavLink>
);
