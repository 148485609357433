import React from 'react';
import { SquareButton } from './SquareButton';
import FilterIcon from '../assets/filter.png';
import CheckIcon from '../assets/check.png';
import styled from 'styled-components';
import { Popover } from './Popover';
import { getFontValue } from '../config/theme';

type FilterButtonProps = {
  size: number;
  options: {
    value: number;
    label: string;
  }[];
  onChange(filter: number): void;
  currentFilters: number[];
};

const Icon = styled.img<{ size: number }>(
  ({ size }) => `
  width: ${size}px;
  height: ${size}px;
`
);

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.bg.activeButton};
  box-shadow: ${({ theme }) => theme.shadow.popover};
  border-radius: 6px;
  border-top-right-radius: 0;
  padding: 8px 0;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  background: transparent;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.tertiary};
  ${getFontValue('s')}

  &:hover {
    background-color: ${({ theme }) => theme.colors.bg.navItem};
  }
`;

const LeftItemColumn = styled.div`
  flex: 1;
  padding: 0 15px;
`;

const RightItemColumn = styled.div`
  padding: 0 15px;
  height: 25px;
  width: 25px;
`;

export const FilterButton: React.FC<FilterButtonProps> = ({
  options,
  size,
  onChange,
  currentFilters,
}) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  return (
    <>
      <SquareButton
        ref={buttonRef}
        size={size}
        onClick={() => setIsOpen(!isOpen)}
        active={isOpen}
      >
        <Icon src={FilterIcon} size={size} />
      </SquareButton>
      <Popover isOpen={isOpen} triggerRef={buttonRef}>
        <Wrapper>
          {options.map((option) => (
            <ListItem key={option.value} onClick={() => onChange(option.value)}>
              <LeftItemColumn>{option.label}</LeftItemColumn>
              <RightItemColumn>
                {currentFilters.includes(option.value) && (
                  <Icon src={CheckIcon} size={25} />
                )}
              </RightItemColumn>
            </ListItem>
          ))}
        </Wrapper>
      </Popover>
    </>
  );
};
