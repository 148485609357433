import React, { MutableRefObject } from 'react';
import ReactDOM from 'react-dom';

type PopoverProps = {
  isOpen: boolean;
  triggerRef: MutableRefObject<HTMLElement | null>;
};

const getPositionFromElement = (element: HTMLElement): React.CSSProperties => {
  const { x, y, width, height } = element.getBoundingClientRect();

  return {
    position: 'absolute',
    top: y + height + window.scrollY,
    right: window.innerWidth - x - width,
    pointerEvents: 'all',
  };
};

export const Popover: React.FC<PopoverProps> = ({
  isOpen,
  children,
  triggerRef,
}) => {
  const popoverRoot = React.useRef<HTMLElement | null>();

  React.useEffect(() => {
    popoverRoot.current = document.getElementById('popover-root');
  }, []);

  if (!popoverRoot.current || !isOpen || !triggerRef.current) {
    return null;
  }

  return ReactDOM.createPortal(
    <div style={getPositionFromElement(triggerRef.current)}>{children}</div>,
    popoverRoot.current
  );
};
