import { decorate, observable, action } from 'mobx';
import { Member } from '../models/Member';
import { api } from '../services/api';
import { Bifrost } from '../decls/bifrost';

export class MemberStore {
  members: Member[] = [];

  getMemberByCharacterId = (id: number): Member | undefined =>
    this.members.find((member) => member.character.id === id);

  setMembers(members: Member[]): void {
    this.members = members;
  }

  async fetchMembersForGuild(guildId: number) {
    try {
      const response = await api.get<Bifrost.API.GuildMembersResponseData>(
        `/guild/guilds/${guildId}/members`
      );
      if (response.status === 200) {
        this.setMembers(response.data);
      }
    } catch (e) {
      // Report this or something.
    }
  }
}

decorate(MemberStore, {
  members: observable,
  setMembers: action,
});
