export const groupArrayOfObjectsByProperty = <
  T extends { [key: string]: any }
>(
  items: T[],
  property: string
): T[][] => {
  const result: {
    [key: string]: any;
  } = {};

  items.forEach((item) => {
    if (!result[item[property]]) {
      result[item[property]] = [];
    }

    result[item[property]].push(item);
  });

  return Object.values(result);
};
