import styled from 'styled-components';

export const ViewContainer = styled.div`
  width: 980px;
  margin: 70px auto 0;
`;

export const Footer = styled.div`
height: 150px;
`;
