import styled, { css } from 'styled-components';
import { getFontValue } from '../config/theme';

export const Table = styled.table(
  ({ theme }) => css`
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 45px;

    th {
      position: relative;
      padding: 14px 0 14px 19px;
      background-color: ${theme.colors.table.headerBg};
      color: ${theme.colors.table.headerText};
      text-align: left;
      ${getFontValue('s')};
    }

    td {
      padding: 9px 0 7px 19px;
      color: ${theme.colors.table.text};
      text-align: left;
      ${getFontValue('s')};
    }

    tr:not(:last-child) {
      border-bottom: 1px solid ${theme.colors.table.separator};
    }
  `
);
